
const axios = require('axios');
import $ from 'cash-dom';

var mapping = {
  'roomtype': 'Kamertype',
  'transporttype': 'Vervoer',
  'departure': 'Vertrekdatum',
  'departure_from': 'Vertrekdatum van',
  'departure_to': 'Vertrekdatum tot',
  'durations': 'Reisuur',
  'occupations': 'Aantal personen',
  'boardtype': 'Verzorging',
  'audience': 'Leeftijdsgroep',
  'category': 'Categorie',
  'departure_month': 'Vertrekmaand',
  'min_age': 'Leeftijd vanaf',
  'max_age': 'Leeftijd tot',
  'countries': 'Bestemming',
  'regions': 'Streek',
  'country': 'Bestemming',
  'departure_guarantee': 'Vertrekgarantie',
};

var display_types = {
  'departure' : 'date',
  // 'departure_from' : 'date',
  // 'departure_to' : 'date',
  'departure_from': 'select',
  'departure_to': 'select',
  'duration': 'select',
  'audience': 'select',
  'departure_guarantee': 'boolean',
  'default': 'select',
};

/* welke filters worden er gebruikt en in welke volgorde: */

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


function identify (el, prefix) {
  var $el = $(el);

  if (!$el.attr('id')) {
    $el.attr('id', uuidv4());
  }
};


function FilterBox (type, options) {

  this._container =  undefined,
  this._selected =  false,
  this._options =  {
    'onChange': function() {},
    'display_type': false,
    'show_departure_as_holidays': false,
  },
  this._datepicker = false,

  this.initialize = function(type, options) {
    if (typeof options == 'undefined') {
      options = {};
    }

    this._type = type;

    if (options.onChange) {
      this._options.onChange = options.onChange
    }

    if (options.display_type) {
      this._options.display_type = options.display_type
    }
    else {
      this._options.display_type = display_types[type] || display_types['default'];
    }

    this._options.show_departure_as_holidays = options.show_departure_as_holidays || this._options.show_departure_as_holidays;

  },

  this.setSelected = function(selectedValue) {
    this._selected = selectedValue;
  },

  this.build = function(container) {

    // var div = $('<div>');
    // div.addClass(this._type);
    // div.addClass('filter-box');

    // var label = $('label', {
    //   className: 'control-label'
    // }).update(mapping[this._type] + ':');

    // div.append(label);

    var el;

    if (this._options.display_type == 'select') {

      var select_container = $('<div>');
      select_container.addClass('col');

      var select = $('<select>');
      select.attr('name', this._type);
      select.addClass('form-select').addClass('mb-3');
      select.on('change', this.onSelectChange.bind(this));

      identify(select);

      // select_container.append(select);
      // container.append(select_container);
      container.append(select);

      el = select;
    }


    if (this._options.display_type == 'checkbox_group') {
      var select_container = $('<div>');
      select_container.addClass('checkbox-group__container');

      var heading = $('h3');

      var select = $('<select>');
      select.attr('name', this._type);
      select.on('change', this.onSelectChange.bind(this));

      identify(select);

      select_container.append(select);

      container.append(select_container);

      el = select;
    }

    if (this._options.display_type == 'boolean') {
      var checkbox_container = $('<div>');
      checkbox_container.addClass('checkbox checkbox-group__container '+ this._type);

      var input = $('<input type="checkbox">');
      input.attr('name', this._type);
      input.on('change', this.onCheckboxChange.bind(this));

      identify(input);

      var label = $('<label>');
      label.attr('for', input.attr('id'));
      // label.html(mapping[this._type]);

      var span = $('<span>');
      span.html(mapping[this._type]);

      checkbox_container.append(input);
      checkbox_container.append(label);
      checkbox_container.append(span);

      container.append(checkbox_container);

      el = input;
    }


    if (this._options.display_type == 'date') {

      var date_container = $('<div>');
      date_container.addClass('search-field__date icon-input');

      var npt = $('<input>');
      npt.addClass('date');
      npt.attr('name', this._type);
      npt.attr('type', 'text');
      npt.attr('placeholder', 'Wanneer wil je vertrekken?');

      identify(npt);

      date_container.append(npt);

      container.append(date_container);

      el = date_container;
    }

    this._container = el;

    return this._container;
  },

  this.onSelectChange = function(ev) {
    console.info([this, ev]);
    var el = ev.currentTarget;
    if (el) {
      this._options.onChange(el.name, el.options[el.selectedIndex].value);
    }
  },

  this.onDateChange = function (dates, formatted, instance, context) {
    if (dates.length >= 2) {
      d = [];
      for (var i = 0, ilen = dates.length; i < ilen; i++) {
        d.push(dates[i].toISOString().split(/T/)[0]);
      }
      this._options.onChange(instance.input.name, d.join(';'));
    }
  },

  this.onCheckboxChange = function (ev) {
    console.info([this, ev]);
    var el = ev.currentTarget;
    if (el) {
      this._options.onChange(el.name, ($(el).is(':checked') ? 1 : 0));
    }
  }

  this.update = function(filteroptions) {
    var that = this;

    console.info({
      'filteroptions': filteroptions
    });

    if (display_types[this._type] == 'select' ||
      (typeof(display_types[this._type]) == 'undefined' && display_types['default'] == 'select')) {

      $(this._container).hide();

      var $select = $(this._container);
      $select.find('option').remove();

      var first_option = $('<option>');
      first_option.attr('value', -1);
      first_option.html(mapping[this._type]);

      $select.append(first_option);

      if (filteroptions.length >= 1) {
        for (var i = 0, ilen = filteroptions.length; i < ilen; i++) {
          var description = filteroptions[i].description[0].toUpperCase();
          description += filteroptions[i].description.substring(1);
          // var description = filteroptions[i].display;

          var option = $('<option>');
          option.attr('value',filteroptions[i].code)
          option.html(description);

          option.addClass(filteroptions[i].available == 1 ? 'available' : 'unavailable');

          // if (filteroptions[i].selected) {
          //   option.attr('selected', 'selected');
          // }

          if (filteroptions[i].code == this._selected) {
            option.attr('selected', 'selected');
          }

          $select.append(option);
        }
        $(this._container).show();
      }
    }

    if (display_types[this._type] == 'date') {

      if( this.instance ) { this.instance.destroy(); }

      var $npt = $(this._container).select('input')[0];

      if ($npt) {
        var dates = [];

        var t = new Date();
        filteroptions.each(function(elm) {
          var a = elm.code.split('-');
          var d = new Date(parseInt(a[0], 10), parseInt(a[1], 10) - 1, parseInt(a[2], 10));

          if (d >= t) {
            dates.push(d);
          }
        });

        sdates = dates.sortBy(function(elm) {
          return elm.getTime();
        });

        var min = false,
            max = false;

        if (sdates.length > 0) {
          min = sdates.first();
          max = sdates.last();
        }
        else {
          min = "today",
          max = null
        }

        selected_dates = [];
        if( that._selected ) {
          selected_dates = that._selected.split(/;/);
        }

        that.instance = flatpickr('#' + $npt.identify(), {
          mode: 'range',
          minDate: min,
          maxDate: max,
          // enable: sdates,
          weekNumbers: true,
          dateFormat: "d M Y",
          locale: "nl",
          onClose: that.onDateChange.bind(that),
          onReady: function () {},
          onOpen: function () {}
        } );

        $npt.setValue( selected_dates.join( ' tot ') );
      }

      $(this._container).show();
    }

    if (display_types[this._type] == 'boolean') {

      $(this._container).parent('.checkbox-group__container').hide();

      if (filteroptions.length >= 1) {
        $(this._container).parent('.checkbox-group__container').show();
      }

    }
  }

  this.initialize(type, options);

};


function CallToActionBox (containerElement, options) {
  this._options = {
    'onClick': function() {},
    'show_departure_as_holidays': false,
  },

  this.initialize = function(containerElement, options) {
    this._options = options;
    this._container = containerElement;
    this._el;
  },

  this.build = function() {
    var div = $('<div>');
    div.addClass('cta-container')

    var btn = $('<button>')
    btn.addClass('btn').addClass('btn--primary').addClass('mb-3');

    this._el = btn;
    btn.on('click', this.onClick.bind(this));

    this._container.append(this._el);
  },
  this.update = function(content) {
    this._el.html(content);
  },
  this.onClick = function(e) {
    this._options.onClick(e);
  }


  this.initialize(containerElement, options);
};

function ResetBox (containerElement, options) {
  this._options = {
    'onClick': function() {},
  },
  this.initialize = function(containerElement, options) {
    this._options = options;
    this._container = containerElement;
    this._ed;
  },
  this.build = function() {
    var div = $('div', {
        'class': 'reset-container'
      }),
      btn = $('button', {
        'class': 'btn-reset button btn-block'
      });

    this._el = btn;
    btn.on('click', this.onClick.bind(this));

    this._container.append(this._el);
  },
  this.update = function(content) {
    this._el.html(content);
  },
  this.onClick = function(e) {
    this._options.onClick(e);
  }


  this.initialize(containerElement, options);
};

function SearchQuery () {
  this._params = {},

  this.initialize = function() {
    this._search = new URLSearchParams(document.location.search);
  },

  this.search = function(name, value) {

    if (Array.isArray(value)) {
      var multi_name = name + '[]';

      if (this._search.has(name)) {
        this._search.delete(name);
      }

      this._search.delete(multi_name);

      value.forEach((el, idx) => {

        idx == 0
          ? this._search.set(multi_name, el)
          : this._search.append(multi_name, el);

      });

      return;
    }

    if (value !== -1 && value !== "-1") {
      this._search.set(name, value);  // _params[name] = value;
      this._search.set('search', name);  // _params['search'] = name;
    }
    else if (this._search.has(name)) { // (this._params[name]) {
      // delete this._params[name];
      this._search.delete(name);
    }
    return;
  },

  this.toString = function() {
    return '?' + this._search.toString();
  }

  this.getParams = function () {
    return this._search;
  }

  this.initialize();
};



function SearchBox (containerElement, options) {
  this.options = {
    'endpoint': '/',
    'load': false,
    'reset': true,
    'filters': [],
    'show_departure_as_holidays': false,
  },

  this.initialize = function(containerElement, options) {
    this._container = $(containerElement);
    this._query = new SearchQuery();
    this._filters = {};
    this._cta = false;

    for (var i in this.options) {
      if (typeof(options[i]) != 'undefined') {
        this.options[i] = options[i];
      }
    }

    if (options.onUpdateFilters) {
      this.onUpdateFilters = options.onUpdateFilters;
    }

    if (options.onAfterUpdateFilters) {
      this.onAfterUpdateFilters = options.onAfterUpdateFilters;
    }

    this.loadFilters();
  },

  this.loadFilters = function() {
    var that = this;

    axios.get(this.options.endpoint, {
      params: this._query.getParams()
    })
      .then(that.parseResponse.bind(that))
      .catch(function (error) {
        console.log(error);
      });

    // $.ajax(this.options.endpoint + this._query.toString(), {
    //   method: 'get',
    //   success: that.parseResponse.bind(that)
    // });
  },

  this.parseResponse = function(xmlHttpResponse) {

    var data = xmlHttpResponse.data;

    // global filters!!
    var filters = this.options.filters || [];
    for (var i = 0, ilen = filters.length; i < ilen; i++) {
      var filter_config = filters[i];

      if (typeof(this._filters[filter_config.search]) == 'undefined') {
        // console.info(filter_config);

        var box = new FilterBox(filter_config.search, {
          'onChange': this.updateFilters.bind(this),
          'display_type': filter_config.display_type,
        });

        box.build(this._container);

        this._filters[filter_config.search] = box;
      }

      var filteroptions = data.filters[filter_config.source] || [];
      var selected_option = data.selected[filter_config.source];

      this._filters[filter_config.search].setSelected(selected_option);
      this._filters[filter_config.search].update(filteroptions);
    }

    if ((!this._reset && this.options.reset) || !this._cta) {
      var cnt = $('div', {
        className: 'controls'
      });

      if (!this._reset && this.options.reset) {
        this._reset = new ResetBox(this._container, {
          'onClick': this.resetPage.bind(this)
        });
        this._reset.build();
        this._reset.update('Opnieuw beginnen');
      }

      if (!this._cta) {
        this._cta = new CallToActionBox(this._container, {
          'onClick': this.reloadPage.bind(this),
        });
        this._cta.build();
      }

    }

    console.info('CTA update: ', data.filters, this.options);

    if( data.filters.departure && this.options.show_departure_as_holidays ) {
      this._cta.update('Toon ' + data.filters.departure.length + ' vakantie' + (data.filters.departure.length > 1 ? 's' : ''));
    }
    else {
      this._cta.update('Toon ' + data.number_of_items + ' vakantie' + (data.number_of_items != 1 ? 's' : '') );
    }

    // this._cta.update('Toon alle vakanties');
  },

  this.onUpdateFilters = function(name, value) {},

  this.updateFilters = function(filter, value) {
    this.onUpdateFilters(filter, value);
    this._query.search(filter, value);
    this.loadFilters();
    this.onAfterUpdateFilters(filter, value);
  },

  this.onAfterUpdateFilters = function(name, value) {},

  this.reloadPage = function() {
    var base = this.options.load || document.location.pathname;
    document.location = base + this._query.toString();
  },

  this.resetPage = function() {
    document.location = document.location.pathname;
  }

  this.initialize(containerElement, options);
};

export { FilterBox, ResetBox, CallToActionBox, SearchQuery, SearchBox};
