
var Cookie = function (options) {
  /* public */ this.name = null;
  /* public */ this.value = null;
  /* public */ this.expire = null;
  /* public */ this.path = null;
  /* public */ this.domain = null;
  /* public */ this.secure = null;
  /* private */ this._cookies_read = false;
  /* private */ this._cookies = [];

  this.initialize = function (options) {
    this.name   = options.name || document.domain;
    this.value  = options.value || '';
    this.expire = options.expire || false;
    this.path   = options.path || false;
    this.domain = options.domain || false;
    this.secure = options.secure || false;
  };

  // this.set = function (name, value) {
  //   this[name] = value;
  //   return true;
  // };

  // this.get = function (name) {
  //   return this[name];
  // };

  this.write = function (name = false) {
    if (name && !this.name) {
      this.name = name;
    }

    if( !this.name ) {
      console.error('no name set for cookie, unable to write cookie to browser');
      return false;
    }

    var cstring = this.name + "=" + encodeURIComponent(this.value) + "; ";

    if (this.expire) {
      var d = new Date();
      d.setTime(d.getTime() + (this.expire * 60 * 1000));
      cstring += "expires=" + d.toGMTString() + "; ";
    }

    if (this.path) {
      cstring += 'path=' + this.path + '; ';
    }

    if (this.domain) {
      cstring += 'domain=' + this.domain + '; ';
    }

    if (this.secure) {
      cstring += 'secure;';
    }

    document.cookie = cstring;

    return true;
  };

  this.read = function (name = false) {

    if (!this._cookies_read) {
      document.cookie.split(';').forEach((cookiestring, idx) => {
        let s = cookiestring.trim().split('=');
        this._cookies[s[0]] = new Cookie({
          'name': s[0],
          'value': decodeURIComponent(s[1])
        });
        this._cookies_read = true;
      });
    }

    if (!name) {
      name = this.name;
    }

    return (this._cookies[name] ? this._cookies[name].value : false);
  };

  this.destroy = function () {
    this.expire = -1;
    this.value = false;
    this.write();
    return;
  };

  this.initialize(options);


};


export {Cookie};
