
import $ from 'cash-dom';
import { Cookie } from './cookie.js';

var CookieBar = function(options){

    this.levels = {
        'init': 0,
        'functional': 1,
        'retargeting': 2,
        'socialsharing': 3
    };

    this.initialize = function(options) {
        console.info('ncCookieBar.initialize');

        var options = options || {};

        // de beschikbare options:
        this.options = {
            'accept_after_pages': (options.accept_after_pages || 3),
            'container': (options.container || '#cookie-bar'),
            'explicitacceptcontrol': (options.explicitacceptcontrol || '#cookies-accept'),
            'default_level': (options.default_level || 2),
            'level_control': (options.level_control || false),
            'start_level': (options.start_level || 1)
        };

        console.info(this.options);

        if (options.onAfterAccept) {
            this.onAfterAccept = options.onAfterAccept;
            this.bound_afteraccept = this.onAfterAccept.bind(this);
        }

        // de settings:
        this.settings = {
            'accepted': false,
            'pages': 0,
            'level': this.options.start_level
        };

        // setup van de CookieSettings:
        var cSettings = new Cookie({'name': 'CookieSettings'});

        // als we hem niet kunnen lezen maken we hem aan
        var csettingsvalue = cSettings.read();
        if (csettingsvalue) {
            // de oude handlers gingen over de emmer van de ", nu we een encodeURIComponent over de JSON string halen is vervangen van " voor ' niet nodig meer
            // om "oude" cookies toch te kunnen parsen moet de onderstaande replace blijven bestaan:
            csettingsvalue = csettingsvalue.replace(/'/g, '"');
            // this.settings = csettingsvalue.evalJSON();
            this.settings = JSON.parse(csettingsvalue);
            // console.info( this.settings );
        } else {
            // als we de cookie niet in kunnen lezen, moeten we hem wel aanmaken!
            this.save();
        }

        // we controleren of we een doorklik hebben op de site:
        if (document.referrer && document.referrer.search(document.domain) >= 0) {
            var has_changed = false;

            if (!this.settings.accepted) {
                // console.debug('increment pageviews by one..');
                this.settings.pages++;
                has_changed = true;
            }

            if (!this.settings.accepted && this.settings.pages >= this.options.accept_after_pages) {
                this.settings.accepted = true;
                this.settings.level = this.options.default_level;
                has_changed = true;
            }

            if (has_changed) this.save();
        }

        this.bound_explicitaccept = this.explicitAccept.bind(this);
        // this.bound_explicitvimeoaccept = this.explicitVimeoAccept.bind(this);

        if (!this.settings.accepted) {
          console.info('cookies not yet accepted');
            $(this.options.container).show();
            $(this.options.explicitacceptcontrol).on('click', this.bound_explicitaccept);
        }

        if (this.settings.accepted) {
          console.info('cookies already accepted');
            this.accept();
        }

        // we zoeken alle
        var aSocialPlugins = $('div.cookiebar-activate');
        // for (var i = 0, ilen = aSocialPlugins.length; i < ilen; i++) {
        //     var pluggable = aSocialPlugins[i];

        //     var aSocialPluginActivateLinks = pluggable.select('.cookiebar-activate-link');
        //     // console.debug('ncCookieBar; Number of facebook links: ' + aSocialPluginActivateLinks.length);
        //     for (var j = 0, jlen = aSocialPluginActivateLinks.length; j < jlen; j++) {
        //         if (aSocialPluginActivateLinks[j].hasClass("cookiebar-nonrefresh")) {
        //             aSocialPluginActivateLinks[j].on('click', this.bound_explicitvimeoaccept);
        //         } else {
        //             aSocialPluginActivateLinks[j].on('click', this.bound_explicitaccept);
        //         }
        //     }
        // }

        return this;
    };

    this.accept = function() {
        this.onBeforeAccept();
        this.onAccept();
        this.onAfterAccept();
        return this;
    };

    this.explicitAccept = function(ev) {
        // console.debug('ncCookieBar.explicitaccept');
        if (ev) {
          ev.stopPropagation();
        }

        if (ev && ev.target) {
            var obj = ev.target;
            if (obj.hasAttribute('data-level')) {
                this.settings.level = obj.getAttribute('data-level');
            }
        }

        if (this.options.level_control && $(this.options.level_control)) {
            console.warn('cookie level control nog niet geimplementeerd!')
        } else {
            this.settings.level = this.settings.level > 0 ? this.settings.level : 3;
        }

        this.settings.accepted = true;

        this.accept();
        this.save();

        this.page_reload();

        return this;
    };

    this.onBeforeAccept = function() {
        // console.debug('ncCookieBar.onBeforeAccept');
    };

    this.onAccept = function() {
        // console.debug('ncCookieBar.onAccept');

        if ($(this.options.container)) {
            // new Effect.BlindUp( this.options.container );
            $(this.options.container).hide();

            if (!$(document.body).hasClass('cookies-accepted')) {
                $(document.body).addClass('cookies-accepted');
            }

            $(document.body).addClass('cookie-level-' + this.settings.level);
        }

        var aSocialPlugins = $('div.cookiebar-activate');

        if (this.settings.level >= this.levels.socialsharing) {
            // for( var i = 0, ilen = aSocialPlugins.length; i < ilen; i++ ) {
            //  var aSocialPluginActivateLinks = aSocialPlugins[i].select('a.cookiebar-activate-link');
            //  for( var j = 0, jlen = aSocialPluginActivateLinks.length; j < jlen; j++ ) {
            //      aSocialPluginActivateLinks[j].remove();
            //  }
            //  aSocialPlugins[i].removeClass('cookiebar-activate');
            // }
            aSocialPlugins.each(function(aSocialPlugin) {
                aSocialPlugin.remove();
            });
        }

        return this;
    };

    this.onAfterAccept = function() {
        // console.debug('ncCookieBar.onAfterAccept');
    },

    this.save = function() {
        // console.debug('ncCookieBar.save');
        this.onBeforeSave();
        this.onSave();
        this.onAfterSave();
        return this;
    };

    this.onBeforeSave = function() {
        // console.debug('ncCookieBar.onBeforeSave');
    };

    this.onSave = function() {
        var cSettings = new Cookie({
          'name': 'CookieSettings',
          'value': JSON.stringify(this.settings),
          'expire': (60 * 24 * 365 * 10), // 10 jaar in minuten
          'path': '/'
        });

        cSettings.write();

        return this;
    };

    this.onAfterSave = function() {
        // console.debug('ncCookieBar.onAfterSave');
    };

    this.page_reload = function() {
        var loc = document.location.href;
        window.location = loc;
    };

    this.initialize(options);
};

export {CookieBar}
